import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import Slider from "react-slick";
import Layout from "../layout";
import SEO from "../components/seo";
import styled from "styled-components";
import {
  mainRed,
  navyBlue,
  aku,
  innerWidth,
  gotham,
  mainWhite,
  gothamBold,
  transHover,
  screen,
} from "../components/common/variables";
import { scaleInBig } from "../components/common/animations";
import { Link as Spy } from "react-scroll";
import Arrow from "../images/svg/right-arrow.svg";
import Close from "../images/svg/close-icon.svg";

const Wrapper = styled.div`
  padding-bottom: 90px;
  padding-top: 124px;
  @media ${screen.small} {
    padding-top: 135px;
  }
  @media ${screen.medium} {
    padding-top: 150px;
  }
  @media ${screen.large} {
    padding-top: 200px;
  }

  .heading {
    color: ${navyBlue};
    font-family: ${aku};
    font-size: 2.1rem;
    max-width: ${innerWidth};
    margin: 0 auto;
    padding-left: 25px;
    padding-right: 25px;
    @media ${screen.small} {
      padding-left: 40px;
      padding-right: 40px;
      font-size: 2.7rem;
    }
    @media ${screen.medium} {
      padding-left: 50px;
      padding-right: 50px;
    }
    @media ${screen.xLarge} {
      padding-left: 0;
      padding-right: 0;
    }

    svg {
      width: 14px;
      margin-right: 8px;
      vertical-align: middle;
      @media ${screen.small} {
        width: 19px;
        margin-right: 14px;
      }
      @media ${screen.medium} {
        width: 24px;
      }

      .body {
        fill: ${mainRed};
        transition: ${transHover};
      }
    }
  }

  .primary-content {
    max-width: ${innerWidth};
    margin: 0 auto;
    padding-left: 25px;
    padding-right: 25px;
    @media ${screen.small} {
      padding-left: 40px;
      padding-right: 40px;
    }
    @media ${screen.medium} {
      padding-left: 50px;
      padding-right: 50px;
    }

    @media ${screen.xLarge} {
      padding-left: 0;
      padding-right: 0;
    }

    &__row {
      display: flex;
      justify-content: space-between;
      margin-top: 15px;
      flex-direction: column;
      @media ${screen.small} {
        flex-direction: row;
        margin-top: 90px;
      }

      &:first-child {
        margin-top: 15px;
        @media ${screen.small} {
          margin-top: 30px;
        }
      }

      .texts-column {
        color: ${navyBlue};
        max-width: 1000px;
        width: 100%;

        p {
          font-family: ${gotham};
          font-size: 1rem;
          margin-bottom: 16px;
          @media ${screen.small} {
            font-size: 1.1rem;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }

        a {
          font-family: ${gothamBold};
          color: ${navyBlue};
          &:hover {
            text-decoration: underline;
          }
        }

        strong {
          font-family: ${gothamBold};
        }

        h1 {
          font-size: 2rem;
          font-family: ${gotham};
          margin: 8px 0;
          @media ${screen.small} {
            margin: 10px 0;
            font-size: 2.2rem;
          }
        }
        h2 {
          font-size: 1.8rem;
          font-family: ${gotham};
          margin: 8px 0;
          @media ${screen.small} {
            margin: 10px 0;
            font-size: 2rem;
          }
        }
        h3 {
          font-size: 1.6rem;
          font-family: ${gotham};
          margin: 8px 0;
          @media ${screen.small} {
            margin: 10px 0;
            font-size: 1.8rem;
          }
        }
        h4 {
          font-size: 1.4rem;
          font-family: ${gotham};
          margin: 8px 0;
          @media ${screen.small} {
            margin: 10px 0;
            font-size: 1.6rem;
          }
        }
        h5 {
          font-size: 1.3rem;
          font-family: ${gotham};
          margin: 8px 0;
          @media ${screen.small} {
            margin: 8px 0;
            font-size: 1.4rem;
          }
        }
        h6 {
          font-size: 1.1rem;
          font-family: ${gotham};
          margin: 8px 0;
          @media ${screen.small} {
            margin: 10px 0;
            font-size: 1.2rem;
          }
        }

        ul,
        ol {
          margin: 15px 0 30px 0;
          padding-left: 35px;
          @media ${screen.small} {
            padding-left: 45px;
          }

          li {
            color: ${navyBlue};
            line-height: 1.5;
            list-style: inherit;
            font-family: ${gotham};
            margin-bottom: 16px;
          }
        }
      }

      .img-column {
        width: 100%;
        order: -1;
        @media ${screen.small} {
          order: 1;
          padding-left: 35px;
          max-width: 330px;
        }
        @media ${screen.medium} {
          padding-left: 45px;
          max-width: 450px;
        }
        @media ${screen.large} {
          max-width: 620px;
          padding-left: 70px;
        }

        &__img {
          @media ${screen.small} {
            margin-bottom: 100px;
          }
          &:first-child {
            margin-bottom: 35px;
            @media ${screen.small} {
              margin-bottom: 100px;
            }
          }
          &:last-child {
            margin-bottom: 15px;
            @media ${screen.small} {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  .featured-project {
    max-width: ${innerWidth};
    margin: 0 auto;
    padding-top: 45px;
    padding-left: 25px;
    padding-right: 25px;
    @media ${screen.small} {
      padding-left: 40px;
      padding-right: 40px;
      padding-top: 90px;
    }
    @media ${screen.medium} {
      padding-left: 50px;
      padding-right: 50px;
    }
    @media ${screen.xLarge} {
      padding-left: 0;
      padding-right: 0;
    }

    &__heading {
      color: ${navyBlue};
      font-family: ${gotham};
      font-size: 1.3rem;
      @media ${screen.small} {
        font-size: 2rem;
      }

      svg {
        width: 14px;
        margin-right: 8px;
        @media ${screen.small} {
          width: 19px;
          margin-right: 14px;
        }
        @media ${screen.medium} {
          width: 24px;
        }

        .body {
          fill: ${navyBlue};
          transition: ${transHover};
        }
      }

      .active {
        .body {
          fill: ${mainRed};
        }
      }
    }

    &__video-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 10px;
      @media ${screen.small} {
        margin-top: 30px;
      }

      .video-item {
        width: 100%;
        height: 200px;
        overflow: hidden;
        position: relative;
        margin: 8px 0;
        width: 100%;
        @media ${screen.small} {
          width: 49%;
          height: 230px;
        }
        @media ${screen.medium} {
          width: 32.5%;
          height: 240px;
        }
        @media ${screen.large} {
          height: 345px;
        }
        @media ${screen.xLarge} {
          margin: 10px 0;
        }

        &__texts {
          cursor: pointer;
          position: absolute;
          bottom: 0;
          top: 0;
          left: 0;
          right: 0;
          transition: ${transHover};

          @media ${screen.withCursor} {
            &:hover {
              background: rgba(200, 29, 41, 0.9);
            }
          }

          .video-title {
            color: ${mainWhite};
            font-family: ${gotham};
            font-size: 1.3rem;
            position: relative;
            margin-top: 20px;
            margin-left: 20px;
            padding-right: 20px;
            max-width: 440px;

            &::after {
              content: "";
              display: block;
              width: 45px;
              height: 3px;
              margin: 18px 0;
              background: ${mainWhite};
            }
          }
        }

        &__video {
          width: 100% !important;
          height: 100% !important;
          object-fit: cover;
          pointer-events: none;
        }
        .react-player__play-icon,
        .react-player__shadow {
          display: none !important;
        }
      }

      &::after {
        content: "";
        overflow: hidden;
        position: relative;
        visibility: hidden;
        width: 100%;
        @media ${screen.small} {
          margin: 0;
          width: 49%;
        }
        @media ${screen.medium} {
          width: 32.5%;
        }
      }
    }

    &__expand-btn {
      background: ${navyBlue};
      cursor: pointer;
      display: table;
      color: ${mainWhite};
      font-family: ${gotham};
      font-size: 1.1rem;
      padding: 12px 30px;
      transition: ${transHover};
      margin-top: 20px;

      &:hover {
        background: ${mainRed};
      }
    }

    &__projects-link {
      background: ${navyBlue};
      transition: ${transHover};
      display: table;
      padding: 16px 29px;
      color: ${mainWhite};
      font-family: ${gotham};
      font-size: 1.1rem;
      &:hover {
        background: ${mainRed};
      }
    }
  }
`;

const GalleryLightBox = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.9);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  overflow: scroll;
  @media ${screen.small} {
    padding: 60px;
  }
  @media ${screen.medium} {
    padding: 100px;
  }
  @media ${screen.large} {
    padding: 50px;
  }
  @media ${screen.xLarge} {
    padding: 0;
  }

  .gallery-subcontainer {
    max-width: 1280px;
    width: 100%;
    background: ${mainWhite};
    padding: 8px 8px 16px 8px;
    position: relative;
    animation: ${scaleInBig} 0.4s ease-in-out;
    @media ${screen.small} {
      padding: 12px 12px 26px 12px;
      max-width: 750px;
    }
    @media ${screen.medium} {
      margin-top: 25px;
      max-width: 800px;
    }
    @media ${screen.large} {
      margin-top: 100px;
      max-width: 940px;
    }
    @media ${screen.xLarge} {
      margin-top: 0;
      max-width: 1280px;
    }

    &__video-container {
      position: relative;

      .video {
        max-width: 1280px;
        max-height: 520px;
        width: 100%;
        object-fit: cover;
        @media ${screen.small} {
          max-height: 800px;
        }
        @media ${screen.medium} {
          max-height: 520px;
        }
        @media ${screen.large} {
          max-height: 520px;
        }
        @media ${screen.miniLarge} {
          max-height: 370px;
        }
        @media ${screen.xLarge} {
          max-height: 900px;
        }
      }
    }

    &__texts-container {
      margin-top: 10px;
      padding: 0 6px;
      @media ${screen.small} {
        margin-top: 28px;
      }

      .headings {
        display: flex;
        justify-content: space-between;

        &__title {
          color: ${navyBlue};
          font-family: ${aku};
          font-size: 1.7rem;
          @media ${screen.small} {
            font-size: 2.3rem;
          }
        }

        &__year {
          color: ${mainRed};
          font-family: ${aku};
          font-size: 1.7rem;
          @media ${screen.small} {
            font-size: 2.3rem;
          }
        }
      }

      .descriptions {
        display: none;
        margin-top: 4px;
        @media ${screen.large} {
          display: flex;
        }

        h6 {
          color: ${mainRed};
          font-family: ${gothamBold};
          font-size: 0.95rem;
          margin-right: 7px;
          @media ${screen.small} {
            font-size: 1.15rem;
            margin-right: 10px;
          }
        }

        li {
          max-width: 600px;
          margin-top: 10px;
          &:first-child {
            margin-top: 0;
          }
          p {
            color: ${navyBlue};
            font-family: ${gotham};
            font-size: 0.92rem;
            @media ${screen.small} {
              font-size: 1.15rem;
            }
          }
        }
      }
    }

    .close-icon {
      cursor: pointer;
      position: absolute;
      top: -11px;
      right: -6px;
      background: ${mainWhite};
      border-radius: 50%;
      width: 47px;
      height: 47px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 20;
      @media ${screen.small} {
        width: 60px;
        height: 60px;
        top: -35px;
        right: -35px;
      }

      svg {
        width: 17px;
        @media ${screen.small} {
          width: 20px;
        }
        .stroke {
          fill: ${navyBlue};
          transition: ${transHover};
        }
        &:hover {
          .stroke {
            fill: ${mainRed};
          }
        }
      }
    }

    .prev-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 20px;
      filter: drop-shadow(-2px 1px 1px rgba(0, 0, 0, 0.5));
      cursor: pointer;
      @media ${screen.small} {
        left: 30px;
      }

      svg {
        transform: scale(-1);
        width: 28px;
        @media ${screen.small} {
          width: 35px;
        }
        .body {
          fill: ${mainWhite};
          transition: ${transHover};
        }
        &:hover {
          .body {
            fill: ${mainRed};
          }
        }
      }
    }

    .next-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 20px;
      filter: drop-shadow(2px 1px 1px rgba(0, 0, 0, 0.5));
      cursor: pointer;
      @media ${screen.small} {
        right: 30px;
      }

      svg {
        width: 28px;
        @media ${screen.small} {
          width: 35px;
        }
        .body {
          fill: ${mainWhite};
          transition: ${transHover};
        }
        &:hover {
          .body {
            fill: ${mainRed};
          }
        }
      }
    }
  }
`;
const DesignPage = ({ data }) => {
  // Prismic content
  const pageTitle = data.content.data.page
    ? data.content.data.page.text
    : `Design`;
  const titleTag = data.content.data.title_tag
    ? data.content.data.title_tag
    : `Design`;
  const metaDescription = data.content.data.meta_description
    ? data.content.data.meta_description
    : `Design`;
  const galleryImages = data.content.data.image_list;

  // hide/show gallery modal
  const [showModal, setModal] = useState(false);

  // controls for next - prev carousel
  const [controls, setControls] = useState(null);

  // image list starter length
  const [currentLength, setCurrentLength] = useState(9);

  // hide gallery modal
  const closeModal = () => {
    setModal(false);
  };

  // show gallery modal
  const openModal = () => {
    setModal(true);
  };

  // load more displayed image list
  const expandGalleryList = () => {
    setCurrentLength(currentLength + 9);
  };

  // listener for closing the modal by user click and key press
  useEffect(() => {
    if (showModal) {
      const detectTarget = (e) => {
        if (e.target.nodeName === "svg" || e.target.nodeName === "path") {
          return false;
        } else {
          // close modal if user has clicked outside the modal
          if (e.target.className.includes("GalleryLightBox")) {
            closeModal();
          }
        }
      };
      // close modal if user has pressed esc key
      const detectKey = (e) => {
        if (e.keyCode === 27) {
          closeModal();
        }
      };

      window.addEventListener("click", detectTarget);

      window.addEventListener("keydown", detectKey);

      return () => {
        window.removeEventListener("click", detectTarget);
        window.removeEventListener("keydown", detectKey);
      };
    }
  });

  // image list that will render to the DOM
  const renderGalleries = galleryImages.slice(0, currentLength);

  // current gallery length and total gallery length for hiding/showing the load more button
  const currentGalleryLength = renderGalleries.length;
  const totalGalleryLength = galleryImages.length;

  const primaryContent = data.content.data.body.map((content) => (
    <div className="primary-content__row" key={content.id}>
      <div
        className="texts-column"
        dangerouslySetInnerHTML={{ __html: content.primary.description.html }}
      />
      <div className="img-column"></div>
    </div>
  ));

  // gallery modal carousel settings
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    arrows: false,
  };

  return (
    <Layout>
      <SEO title={titleTag} description={metaDescription} />
      <Wrapper>
        <h1 className="heading">
          <Arrow />
          {pageTitle}
        </h1>
        <div className="primary-content">{primaryContent}</div>
        <section id="gallery-thumbnail" className="featured-project">
          <h2 className="featured-project__heading">
            <Spy
              to="gallery-thumbnail"
              activeClass="active"
              spy={true}
              smooth={true}
              offset={-150}
            >
              <Arrow />
            </Spy>
            Our Latest Examples
          </h2>
          <ul className="featured-project__video-list">
            {renderGalleries.map((item, i) => (
              <li
                className="video-item"
                key={i}
                onClick={() => {
                  openModal();
                  controls.slickGoTo(i);
                }}
              >
                <div className="video-item__texts">
                  <h2 className="video-title">{item.title}</h2>
                </div>
                <img
                  className="video-item__video"
                  src={item.image.url}
                  alt={item.image.alt}
                />
              </li>
            ))}
          </ul>
          {currentGalleryLength !== totalGalleryLength && (
            <p
              className="featured-project__expand-btn"
              onClick={expandGalleryList}
            >
              Load More
            </p>
          )}
        </section>

        <GalleryLightBox style={{ display: showModal ? `flex` : `none` }}>
          <div className="gallery-subcontainer">
            <Slider
              {...settings}
              ref={(c) => {
                setControls(c);
              }}
            >
              {galleryImages.map((gallery, i) => (
                <div key={i}>
                  <div className="gallery-subcontainer__video-container">
                    <img
                      className="video"
                      src={gallery.image.url}
                      alt={gallery.image.alt}
                    />
                  </div>
                  <div className="gallery-subcontainer__texts-container">
                    <div className="headings">
                      <h1 className="headings__title">{gallery.title}</h1>
                    </div>
                    <div className="descriptions">
                      {gallery.credits && <h6>Credits:</h6>}
                      <ul>
                        <li>
                          <p>{gallery.credits}</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>

            <span className="close-icon" onClick={closeModal}>
              <Close />
            </span>
            <span
              className="prev-icon"
              onClick={controls && controls.slickPrev}
            >
              <Arrow />
            </span>
            <span
              className="next-icon"
              onClick={controls && controls.slickNext}
            >
              <Arrow />
            </span>
          </div>
        </GalleryLightBox>
      </Wrapper>
    </Layout>
  );
};

export default DesignPage;

export const data = graphql`
  {
    content: prismicDesignPage {
      data {
        title_tag
        meta_description
        page {
          text
        }
        body {
          ... on PrismicDesignPageDataBodyContent {
            id
            primary {
              description {
                html
              }
            }
          }
        }
        image_list {
          image {
            alt
            url
          }
          credits
          title
          edit_published_date
        }
      }
    }
  }
`;
